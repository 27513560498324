import React, { Component } from 'react';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';

import { AppAsideToggler, AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../assets/img/logo.png'
import sygnet from '../../assets/img/brand/sygnet.svg'

import { withRouter } from 'react-router-dom'

const logdown = require('logdown')
const console = logdown('rx-dashboard-default-header', { prefixColor: '#009688' })

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {
    logo: logo,
    logoMinimized: sygnet
};

class DefaultHeader extends Component {

  constructor(props){
    super(props);
    console.log(`log props header: ${JSON.stringify(props)}`, props);
    this.state = {
      logo: logo,
      logoMinimized: sygnet
    }
     
  }

  doLogout = async(e) =>{

    console.log(`in doLogout with ${this.props.loginManager}`);

    try{
      await this.props.loginManager.doLogout();
      console.log('after logout');
      setTimeout(()=>{
        console.log('try to push');
        this.props.history.push('/login');
      }, 200);
    }catch(err){
      console.log(`in error with logout: ${err}`);
    }
  }  
  
  componentDidMount = async () => {
    
    if(this.props.logo){
      this.props.logo.then((data)=>{
        
        console.log(`in new data: `, data);
        this.setState({logo: data.default})
        
      });
    }
    if(this.props.logoMinimized){
      this.props.logoMinimized.then((data)=>{
        
        console.log(`in new data: `, data);
        this.setState({logoMinimized: data.default})
        
      });
    }    
  }

  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: this.state.logo, width: 150, height: 25, alt: 'rx-dashboard Logo' }}
          minimized={{ src: this.state.logoMinimized, width: 30, height: 30, alt: 'rx-dashboard Logo' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />

        {/*<Nav className="d-md-down-none" navbar>
          <NavItem className="px-3">
            <NavLink href="/">Dashboard</NavLink>
          </NavItem>
          <NavItem className="px-3">
            <NavLink href="#/users">Users</NavLink>
          </NavItem>
          <NavItem className="px-3">
            <NavLink href="#">Settings</NavLink>
          </NavItem>
        </Nav>*/}
        <Nav className="ml-auto" navbar>
          {/*<NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-bell"></i><Badge pill color="danger">5</Badge></NavLink>
          </NavItem>
          <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-list"></i></NavLink>
          </NavItem>
          <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-location-pin"></i></NavLink>
          </NavItem>*/}
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <img src={'assets/img/outsiders.png'} className="img-avatar" alt="admin@bootstrapmaster.com" />
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
              {/*<DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>
              <DropdownItem><i className="fa fa-bell-o"></i> Updates<Badge color="info">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-envelope-o"></i> Messages<Badge color="success">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-tasks"></i> Tasks<Badge color="danger">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-comments"></i> Comments<Badge color="warning">42</Badge></DropdownItem>
              <DropdownItem header tag="div" className="text-center"><strong>Settings</strong></DropdownItem>
              <DropdownItem><i className="fa fa-user"></i> Profile</DropdownItem>
              <DropdownItem><i className="fa fa-wrench"></i> Settings</DropdownItem>
              <DropdownItem><i className="fa fa-usd"></i> Payments<Badge color="secondary">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-file"></i> Projects<Badge color="primary">42</Badge></DropdownItem>
              <DropdownItem divider />
              <DropdownItem><i className="fa fa-shield"></i> Lock Account</DropdownItem>*/}
              <DropdownItem  onClick={this.doLogout}><i className="fa fa-lock"></i> Logout</DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
        {/*<AppAsideToggler className="d-md-down-none" />
        <AppAsideToggler className="d-lg-none" mobile  />*/}
        {/* <AppAsideToggler className="d-md-down-none aside-menu-show" data-toggle="aside-menu-show" /> */}
        {/* <AppAsideToggler className="d-lg-none" mobile /> */}
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default withRouter(DefaultHeader);
