import './App.css'

console.log(`before stage: `);
console.log(`IN STAGE: #${STAGE}#`);
import React, { Component } from 'react'
import { RXDashboard } from './libs/rx-dashboard'
import Amplify, { PubSub } from 'aws-amplify';
import Auth from '@aws-amplify/auth'

const appLoader = (page) => import(`./pages/${page}`)
const publicAppLoader = (page) => import(`./pages/public/${page}`)

const loaders = {
  "app": appLoader,
  "public": publicAppLoader
}


const logo = import('../public/assets/img/logoScritta.png')
const logoMinimized = import('../public/assets/img/logoMinimized.png')

import logoLoad from '../public/assets/img/logo.png';

const loading = () => <div style={{
  width: "100%", position: "relative", textAlign: "center", height: '80vh',
  display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
}}
><img src={logoLoad} alt="Logo" /></div>

var config = {}

if (STAGE == "dev") {
  config = require('./config-dev.json')
} else if (STAGE == 'prod') {
  config = require('./config-prod.json')
} else if (STAGE == 'migration') {
    config = require('./config-migration.json')
} else {
  console.log(`unknown stage`);
}

const storage = window.localStorage;
storage.STAGE=STAGE;



class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: config
    }
  }

  componentDidMount = async () => {
    console.log('in component did mount');
    await this.addNameToNavBar();
  }

  addNameToNavBar = async () => {
    const userData = await Auth.currentAuthenticatedUser();
    const sub = userData.attributes.sub;
    var name = ""
    if (STAGE == "dev") {
      name = userData.attributes.name;
    } else if (STAGE == "prod") {
      name = `${userData.attributes.given_name} ${userData.attributes.family_name}`;
    }
    config.navigation[config.navigation.length-1].name = "Utente: " + name + " - " + sub;// + userData.Cognitouser.attributes.name;
    //this.setState()
    this.setState({ config: config })
    //console.log(`in config00: `, userData)
    //return config;
  }
  render() {


    return <RXDashboard events={{ onLogin: (data) => this.addNameToNavBar() }} loading={loading} logo={logo} logoMinimized={logoMinimized} configuration={this.state.config} loaders={loaders} className="aside-menu-fixed" />
  }
}

export default App
