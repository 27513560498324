import React, {Component} from 'react';
import {HashRouter, Route, Switch, Router } from 'react-router-dom';
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';

import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.scss'

// Containers
import {DefaultLayout} from './containers';
import {PublicLayout} from './containers';
// Pages
// import {Login, Page404, Page500, Register} from './views/Pages';
// import {Page404, Page500, Register} from './views/Pages';

// import { renderRoutes } from 'react-router-config';
import { Login, LoginManager, loginStore as LoginManagerStore, LoginActions as LoginManagerActions, PrivateRoute } from '../rx-login';

// const Routes = require('./config/Configuration.json')
// const RoutesLoader = require('./config')
import { getRoutes, getPublicRoutes } from './routes';

const logdown = require('logdown')
const console = logdown('rx-dashboard', { prefixColor: '#00bcd4' })

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);

  return (
    React.createElement(component, finalProps)
  );
}

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => {
      return renderMergedProps(component, routeProps, rest);
    }}/>
  );
}

import { createHashHistory as createHistory } from "history";

const storage = window.localStorage;


class PublicRoute extends Component {
  render(){
    return (<Route render={props =>
      <this.props.component {...this.props} />
    }/>)
  }
}

class App extends Component {
  

  constructor(props){
    super(props);

    this.loginManager = new LoginManager();
    this.history = createHistory();
    //ADDED WEBHOOKS
    this.webhooksEndpoint = this.props.configuration.authentication.configuration.webhooksEndpoint ? this.props.configuration.authentication.configuration.webhooksEndpoint : null
    
    console.log(`##31 - before this.webhook Endpoint`, this.webhooksEndpoint)
    if (this.webhooksEndpoint){
      
      this.webhooks = this.props.configuration.authentication.configuration.webhooks ? this.props.configuration.authentication.configuration.webhooks : {}
      API.configure({
        endpoints: [
          {
            name: "LoginWebhook",
            endpoint: this.webhooksEndpoint,
            custom_header: async () => { 
              return {Authorization: (await Auth.currentSession()).idToken.jwtToken}
            }
          }
        ]
      });
      console.log(`after configure LoginWebhook`)
    }
    
    var configurationParams = {
      region: this.props.configuration.authentication.configuration.region,
      identityPoolId: this.props.configuration.authentication.configuration.identityPoolId,
      userPoolId: this.props.configuration.authentication.configuration.userPoolId,
      userPoolClientId: this.props.configuration.authentication.configuration.userPoolWebClientId,
    };
    
    console.log(`##32 before pubsub`)
    if (this.props.configuration.authentication.configuration.pubsub){
      console.log(`##33 in pubsub`)
      configurationParams.pubsub = true
      configurationParams.pubsubEndpoint = this.props.configuration.authentication.configuration.pubsubEndpoint
    }

    this.loginManager.configure(configurationParams); 
    console.log(`##34 after configure`)
    var authenticated = false;

    console.log(`try to load storage authenticated: ${storage.authenticated}`);
    if (storage.authenticated == true || storage.authenticated == "true") {
      authenticated = true;
      
      //Event for resume session
      (async () => {

        console.log(`3001d: in resume session`)
        if (this.props.events.onResumeLogin){
            var data = (await Auth.currentCredentials())
            this.props.events.onResumeLogin(data)
        }
      
      })()
    }


    this.state = {authenticated}

    let unsubscribe = LoginManagerStore.subscribe( async() => {

      const state = LoginManagerStore.getState();
      console.log(`APP loginStore state: ${JSON.stringify(state)}`);
      
      const credentials = (await Auth.currentCredentials()).identityId
      console.log(`credentials: `, credentials)
      
      switch (state.state) {
        case LoginManagerActions.LOGIN_DONE:
          
          console.log(`in this.webhooks: `, this.webhooks)
          
          if(this.webhooks !== undefined && this.webhooks.onLogin !== undefined){
            
            let data = { // OPTIONAL
              body: {Identity: credentials}, // replace this with attributes you need
              headers: {} // OPTIONAL
    }
            const result = await API.post("LoginWebhook", this.webhooks.onLogin, data);
            console.log(`in result with: `, result)
          }
          this.setState({ authenticated: true });
          
          //Add login data to events
          // console.log(`3001: onLogin: `, this.props.events)
          // console.log(`3001b: onLogin: `, this.props.events.onLogin)
          if (this.props.events.onLogin){
            var data = (await Auth.currentCredentials())
            this.props.events.onLogin(data)
          }
          
          //route to home
          this.history.push('/');
          
          break;
        case LoginManagerActions.REFRESH_AUTH:
          console.log(`login done - change to authenticated: ${storage.jwtToken}`);
          break;
        case LoginManagerActions.EXPIRED:
        case LoginManagerActions.LOGOUT:
          console.log('in logout for set authenticated false');
          this.setState({ authenticated: false });
          storage.removeItem("authenticated");
          break;

        default:
          break;
      }

    });
  }


  render () {
    
    //check publicArea
    const hasPublic = this.props.configuration.publicArea != undefined && this.props.configuration.publicArea ? true : false;
    const publicRoutes = hasPublic ? getPublicRoutes(this.props.configuration, this.props.loaders, this.props.loading) : [];
    
    const routes = getRoutes(this.props.configuration, this.props.loaders, this.props.loading)
    // const routes = []
    return (
      <Router history={this.history}>
        <Switch>
          <PropsRoute exact path="/login" name="Login Page" component={Login} loginTitle={this.props.configuration.authentication.configuration.loginTitle}/>
          {/* <Route exact path="/register" name="Register Page" component={Register} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} /> */}
          
          { hasPublic &&
            <PublicRoute path="/public/" name="Public" component={PublicLayout} logo={this.props.logo} logoMinimized={this.props.logoMinimized} routes={publicRoutes} managers={this.props.managers} dashboardConfiguration={this.props.configuration} layoutConfiguration={this.props.configuration.layoutConfiguration} defaultRoute={this.props.configuration.defaultPublicRoute}/>
          }
          
          <PrivateRoute path="/" name="Home" component={DefaultLayout} logo={this.props.logo} logoMinimized={this.props.logoMinimized} routes={routes} managers={this.props.managers} dashboardConfiguration={this.props.configuration} layoutConfiguration={this.props.configuration.layoutConfiguration} defaultRoute={this.props.configuration.defaultRoute} authenticated={this.state.authenticated} loginManager={this.loginManager}/>
        </Switch>
      </Router >
    );
  }
}

App.defaultProps = {
    loaders: {},
    events: {},
    managers: {},
    logo: null,
    logoMinimized: null,
    loading: ()=><div>RX Loading...</div>
}

export default App;
