import React from 'react'
import App from './App';

export class RXDashboard extends React.Component{

    render = () => <App configuration={this.props.configuration} loading={this.props.loading} logo={this.props.logo} logoMinimized={this.props.logoMinimized} loaders={this.props.loaders} events={this.props.events} managers={this.props.managers} />

}

RXDashboard.defaultProps = {
    logo: null,
    logoMinimized: null,
    loading: ()=><div>RX Loading...</div>
}