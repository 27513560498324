import React, { Component } from 'react';
import { Nav, NavItem, NavLink, Progress, TabContent, TabPane, ListGroup, ListGroupItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppSwitch } from '@coreui/react'
import classNames from 'classnames';

const propTypes = {
    children: PropTypes.node,
};

function toggleClasses (toggleClass, classList, force) {
    const level = classList.indexOf(toggleClass)
    const removeClassList = classList.slice(0, level)
    removeClassList.map((className) => document.body.classList.remove(className))
    document.body.classList.toggle(toggleClass, force)
}

const defaultProps = {};


const asideMenuCssClasses = [
    'aside-menu-show',
    'aside-menu-sm-show',
    'aside-menu-md-show',
    'aside-menu-lg-show',
    'aside-menu-xl-show'
  ];

class RXAside extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '4',
            show: false
        };
    }

    componentDidMount = () => {
      
        this.actionStoreUnsubscribe = this.props.actionStore.subscribe(()=>{
            let state = this.props.actionStore.getState();
            
            switch (state.state) {
                case this.props.states.COMPONENT_LOADED:
                    console.log(`in aSide Component Loaded`)
                    this.setState({component: state.component, show: true})
                    this.showAside()
                    break;
                case this.props.states.COMPONENT_UNLOADED:
                    this.setState({show: false})
                    this.hideAside()
                default:
                    break;
            }
        })    
    }
    
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    showAside = () => {
        const display = 'lg';
        const cssTemplate = `aside-menu-${display}-show`;
        let [cssClass] = asideMenuCssClasses[0];
        if (display && asideMenuCssClasses.indexOf(cssTemplate) > -1) {
            cssClass = cssTemplate;
        }
        document.body.classList.add(cssClass)
    }

    hideAside = () => {
        const display = 'lg';
        const cssTemplate = `aside-menu-${display}-show`;
        let [cssClass] = asideMenuCssClasses[0];
        if (display && asideMenuCssClasses.indexOf(cssTemplate) > -1) {
            cssClass = cssTemplate;
        }
        document.body.classList.remove(cssClass)
    }

    toggleAside = () => {
        
        const display = 'lg';
        const cssTemplate = `aside-menu-${display}-show`;
        let [cssClass] = asideMenuCssClasses[0];
        if (display && asideMenuCssClasses.indexOf(cssTemplate) > -1) {
            cssClass = cssTemplate;
        }
        console.log(`before toggle ${cssClass} and ${asideMenuCssClasses}`)
        toggleClasses(cssClass, asideMenuCssClasses);
    }

    
    render() {

        // eslint-disable-next-line
        const { children, ...attributes } = this.props;

        return (
            <React.Fragment>
                {this.state.component}
                {/* <Nav tabs>
                    <NavItem>
                        <NavLink className={classNames({ active: this.state.activeTab === '4' })}
                                onClick={() => {
                                this.toggle('4');
                                }}>
                        <i className="icon-speech"></i>
                        </NavLink>
                    </NavItem>             
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="4">
                        <ListGroup className="list-group-accent">
                        <ListGroupItem className="list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small">BASIC MODULE</ListGroupItem>
                        <ListGroupItem 
                            draggable={true}
                            onDragStart={event => {
                                event.dataTransfer.setData("storm-diagram-node", JSON.stringify({"text": "ciao"}));
                            }}
                            action tag="a" href="#" className="list-group-item-accent-warning list-group-item-divider">
                            <div><strong>Text</strong> module</div>
                            <div><small>Simple Text Module</small></div>

                        </ListGroupItem>
                        <ListGroupItem 
                            draggable={true}
                            onDragStart={event => {
                                event.dataTransfer.setData("storm-diagram-node", JSON.stringify({"text": "ciao"}));
                            }}
                            action tag="a" href="#" className="list-group-item-accent-primary list-group-item-divider">
                            <div><strong>Button</strong> module</div>

                        </ListGroupItem>                    
                        </ListGroup>   
                    </TabPane>
                </TabContent> */}
            </React.Fragment>
        );
    }
}

RXAside.propTypes = propTypes;
RXAside.defaultProps = defaultProps;

export default RXAside;
