import React, {Component} from 'react';
import Singleton from 'react-singleton'
import { createStore } from 'redux'
// import AWS from "aws-sdk";
// import Amplify from 'aws-amplify';
//import { Auth } from 'aws-amplify';
import Auth from '@aws-amplify/auth';
import Amplify, { PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';

const storage = window.localStorage;

const logdown = require('logdown')
const console = logdown('rx-login-login-manager', { prefixColor: '#689f38' })

export const LoginActions = {
    INIT: 0,
    LOGIN: 1,
    REQUEST_NEW_PASSWORD: 2,
    LOGIN_DONE: 3,
    EXPIRED: 4,
    LOGOUT: 5,
    REFRESH_AUTH: 6,
    RESET: 7
};

export const LoginStates = {
    UNDEFINED: -1,
    INITIALIZED: 0,
    LOGIN: 1,
    REQUEST_NEW_PASSWORD: 2,
    LOGIN_DONE: 3,
    EXPIRED: 4,
    LOGOUT: 5,
    REFRESH_AUTH: 6,
    RESET: 7
};

export const loginStore = createStore(LoginReducer);

function LoginReducer(state = 0, action){
    console.log(`in reducer with: ${state} and action: ${JSON.stringify(action)}`);
    switch (action.type) {
        
        case LoginActions.INIT:
            return {state: LoginStates.INITIALIZED};
    
        case LoginActions.LOGIN:
            return {username: action.username, password: action.password, state: LoginStates.LOGIN, cb:action.cb};

        case LoginActions.REQUEST_NEW_PASSWORD:
            return {state: LoginStates.REQUEST_NEW_PASSWORD, password: action.password, name: action.name, cb:action.cb};

        case LoginActions.LOGIN_DONE:
            return {state: LoginStates.LOGIN_DONE};

        case LoginActions.EXPIRED:
            return {state: LoginStates.EXPIRED};

        case LoginActions.LOGOUT:
            return {state: LoginStates.LOGOUT};

        case LoginActions.REFRESH_AUTH:
            return {state: LoginActions.REFRESH_AUTH, accessKeyId: action.accessKeyId, secretAccessKey: action.secretAccessKey, sessionToken: action.sessionToken};
    
        case LoginActions.RESET:
            return {state: LoginActions.RESET, verification_code: action.verification_code, new_password: action.new_password, username: action.username, cb:action.cb}
        default:
            return {state: LoginStates.UNDEFINED};
            
    }
}


export class LoginManager extends Component {

    constructor(props){
        
        super(props);
        
        console.log(`in LoginManager with props: ${props}`);        

        this.state = {}

        // this.loginStore = loginStore;
        this.doLogin = this.doLogin.bind(this);
        this.doNewPassword = this.doNewPassword.bind(this);
        // this.doRefresh = this.doRefresh.bind(this);
        // this.doLogout = this.doLogout.bind(this);

        console.log('in login manager init');
        console.log(`state: ${JSON.stringify(this.state)}`);
        var _this = this;
        let unsubscribe = loginStore.subscribe(() => {

            let state = loginStore.getState();
            console.log(`in login store event with: ${JSON.stringify(state)}`);
            
            switch (state.state) {

                case  LoginActions.LOGIN:
                    this.doLogin(state.username, state.password, state.cb);
                    break;
            
                case LoginActions.REQUEST_NEW_PASSWORD:
                    console.log(`in request new password with password: ${state.password}`);
                    this.doNewPassword(state.name, state.password, state.cb)
                    break;

                case LoginActions.EXPIRED:
                    this.doLogout();
                    console.log(`expired login`);
                    break;

                case LoginActions.LOGOUT:
                    this.doLogout();
                    console.log(`logout`);
                    break;

                case LoginActions.RESET:
                    console.log(`in reset with state: ${JSON.stringify(state)}`);
                    this.doResetPassword(state.verification_code, state.username, state.new_password, state.cb);
                    break;
                    
                default:
                    break;
            }
        });

    }
    
    
    configure(configuration_data){
        
        console.log(`##11 in configuration_data: ${JSON.stringify(configuration_data)}`);
        this.configuration_data = configuration_data;
        
        if (configuration_data.pubsub){
            
            console.log("##12 AddPluggableIOT")
            Amplify.addPluggable(new AWSIoTProvider({
                aws_pubsub_region: `${configuration_data.region}`,
                aws_pubsub_endpoint: `${configuration_data.pubsubEndpoint}`,
            }));
            
        }
        
        var config = {
            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            identityPoolId: `${configuration_data.identityPoolId}`,
            
            // REQUIRED - Amazon Cognito Region
            region: `${configuration_data.region}`,
        
            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: `${configuration_data.userPoolId}`,
        
            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: `${configuration_data.userPoolClientId}`
              
        };
 
        console.log(`config: ${JSON.stringify(config)}`);    
        Auth.configure(config);
    }

    
    async doLogin(username, password, cb){


        try{
            var result = await Auth.signIn(username, password)
            // .then(user => console.log(user))
            // .catch(err => console.log(err));
            console.log(`in result2 with ${JSON.stringify(result)}`);

            if(result.challengeName != undefined && result.challengeName == 'NEW_PASSWORD_REQUIRED'){
                
                console.log(`need to change password`);
                this.userAttributes = result.challengeParam.userAttributes;
                this.cognitoUser = result;
                cb(null, {error: 'NewPasswordRequired'})
            }else{
                storage.authenticated = true;
                loginStore.dispatch({type: LoginActions.LOGIN_DONE});
            }
        }catch(err){
            console.log(err);
            console.log(`in error with ${JSON.stringify(err)}`);

            if (err.code=='PasswordResetRequiredException'){

                console.log('specific error');
                cb(null, {error: 'PasswordResetRequiredException'});

            }else if (err.code=='UserNotFoundException'){

                console.log('user not found exception');
                cb(null, {error: 'UserNotFoundException'});
            }else if (err.code=='NotAuthorizedException'){

                console.log('Incorrect username or password');
                cb(null, {error: 'NotAuthorizedException'});
            }

        }

    }
    
    // added from riale app
    forgotPasswordRequest = async (email) => {
        try {
          const request = await Auth.forgotPassword(email)
        } catch (error) {
          console.log('with forgotPassword error: ', error)

          throw error;
        }
      }

    doResetPassword = async(verification_code, username, password, cb)=>{

        try{
            var result = await Auth.forgotPasswordSubmit(username, verification_code, password)
            console.log(`in result with ${JSON.stringify(result)}`);
            cb({success: true}, null)
        }catch(err){
            console.log(err);
            console.log(`in error with ${JSON.stringify(err)}`);

            cb(null, err);
        }
    }


    doLogout = async()=>{
        
        return new Promise(async (fulfill, reject)=>{
            try{

                var result = await Auth.signOut();
                console.log(`done signOut with ${JSON.stringify(result)}`);
                storage.authenticated = false;
                fulfill();
            }catch(err){
                console.log(`in sign out error: ${err}`);
                reject();
            }
        });

    }
    
    
    async doNewPassword(name, password, cb){

        try{

            var result = await Auth.completeNewPassword(this.cognitoUser, password, {name: name});
            console.log(`completeNewPasswordChallenge: `, result);
            cb(result, null);

        }catch(err){

            console.log(`in error: ${err}`);
            
            //Temporary FIX for multiname cognito:
            const values = name.split(" ");
            var given_name = values[0];
            var family_name = values.length>0?values[1]:values[0];
            console.log(`try to complete with `, given_name, family_name)
            try{
                var result = await Auth.completeNewPassword(this.cognitoUser, password, 
                    {given_name: given_name, family_name: family_name});
                console.log(`completeNewPasswordChallenge: `, result);
                cb(result, null);                    
            }catch(err){
                console.log(`error 2 with completeNewPasswordChallenge: `, err);
                cb(null, err);
            }

        }

    }


    async doResetPassword(name, code, password, cb){

        try{

            var result = await Auth.forgotPasswordSubmit(this.cognitoUser.username, code, password);
            console.log(`complete change password: ${result}`);
            cb(result, null);

        }catch(err){

            console.log(`in error: ${err}`);
            console.log(`doResetPassword: ${err}`);
            cb(null, err.code);            

        }

    }
    
}


export var withLogin = (WrappedComponent, manager) =>{

    // ...and returns another component...
    return class extends React.Component {
        
        constructor(props) {
            super(props);
            this.manager = manager;
        }

        render() {
            return <WrappedComponent loginManager={this.manager} {...this.props} />;
        }

    };
}

// export const LoginManager = _LoginManager;
// module.exports = {
//     manager: new LoginManager(),
//     store: loginStore,
//     actions: LoginActions,
//     withLogin: withLogin
// }