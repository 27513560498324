import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
// import navigation from '../../_nav';
// routes config
// import routes from '../../routes';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import { createStore } from 'redux'

class PublicLayout extends Component {

  constructor(props){
    super(props);
    console.log("In public layout");
    //console.log(`NEW defaultLayout log props: ${Object.keys(props)}`);
    //console.log(`component: ${props.component}`)
    //console.log(`dashboardConfiguration: ${props.dashboardConfiguration}`)
    this.state = {
      navigation: {items: this.props.dashboardConfiguration.navigation}
    }

    //RETRIEVE AND BUILD ROUTES FROM CONF
    // this.routes = routes(this.props.dashboardConfiguration)
    this.routes = this.props.routes
  }


  render() {

    const {component, ...props} = this.props

    return (
      <div>
        {/*<AppHeader fixed>
          <DefaultHeader logo={this.props.logo} loginManager={this.props.loginManager}/>
        </AppHeader> */}
        <div>
          {/*<AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={this.state.navigation} {...props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>*/}
          <main className="main">
            {/*<AppBreadcrumb appRoutes={this.routes}/>*/}
            <Container fluid>
              <Switch>
                {this.props.routes.map((route, idx) => {
                    return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                        <route.component {...props} actionStore={this.actionStore} managers={this.props.managers} />
                      )} />)
                      : (null);
                  },
                )}
                <Redirect from="/" to={this.props.defaultRoute} />
              </Switch>
            </Container>
            
            {/* <div class="fixed-bottom" style={{height:300, backgroundColor: 'green', zIndex: 1}}>
                  Ciao
            </div> */}
            {/* <div style={{backgroundColor: 'red', bottom: 0, height: 400, left:0, right:0, position:'absolute'}}>
                <div style={{backgroundColor:'white', position: 'absolute', top:0, left:0, right:0, height:32}}>
                    <div className="float-right"><i className="icon-phone">Test</i></div>
                </div>
            </div>    */}
          </main>
        </div>
        {/* <AppFooter>
          <DefaultFooter configuration={this.props.layoutConfiguration.footer}/>
        </AppFooter> */}
      </div>
    );
  }
}

PublicLayout.defaultProps = {
  logo: null
}

export default PublicLayout;
