import React, {Component} from 'react';

import{ Login } from './Login';
import {
  LoginManager as manager, 
  loginStore as store,
  LoginActions as actions, 
  withLogin
} from './LoginManager';
import {Route, Redirect} from 'react-router-dom';
import { createBrowserHistory } from 'history';

class Fancy extends Component {
  render() {
    return (
      <div>
        This is so Fancy!
      </div>
      
    )
  }
}

const logdown = require('logdown')
const console = logdown('rx-login-private-route', { prefixColor: '#689f38' })

export class PrivateRoute extends Component {
  
  constructor(props){
    super(props);
    console.log(`in privateroute login with props: ${JSON.stringify(props)}`);
    
    let unsubscribe = store.subscribe(() => {
      
      let state = store.getState();
      console.log(`privateroute APP loginStore state: ${JSON.stringify(state)}`);
      
      switch (state.state) {
        case actions.LOGIN_DONE:
          console.log('privateroute login done - change to authenticated');
          console.log(`privateroute before state: ${JSON.stringify(this.state)}`);
          // this.setState({authenticated: true});
          console.log(`privateroute privateroute before history push: ${this.props}`);
          //this.props.history.push('/');
          console.log(`privateroute after state: ${JSON.stringify(this.state)}`);
          break;

        case actions.EXPIRED:
        case actions.LOGOUT:
          console.log('in logout for set authenticated false');
          // this.setState({authenticated: false});
          break;
          
        default:
          break;
      }

    });    
  }
  
  render(){
    
    console.log(`in privateroute render with auth ${this.props.authenticated}`);
    if(this.props.authenticated){
      console.log(`in authenticated`);
      return (
        <Route render={props =>
           <this.props.component loginManager={this.props.loginManager} {...this.props} />
        }/>
      )
    }else{
      console.log(`not authenticated - redirect to login`);
      return (
        <Redirect to={{
          pathname: '/login',
          state: { from: this.props.location }
        }}/>
      )
    }
    // return (
    //   <Route {...this.props} render={props => (
    //     props.authenticated ? (
    //       <this.props.component loginManager={this.props.loginManager} {...this.props} />
    //     ) : (
    //       <Redirect to={{
    //         pathname: '/login',
    //         state: { from: props.location }
    //       }}/>
    //   )
    // )}/>
    //)

  }
}


// const PrivateRoute = ({ component: Component, authenticated: authenticated, loginManager: loginManager, ...rest }) => { 
//   console.log('in private route with authenticated: ' + authenticated);
//   return (
//     <Route {...rest} render={props => (
//       authenticated ? (
//         <Component loginManager={loginManager} {...rest} />
//       ) : (
//         <Redirect to={{
//           pathname: '/login',
//           state: { from: props.location }
//         }}/>
//       )
//     )}/>
//   )
// }

// module.exports =  {
//   Fancy: Fancy,
//   Login: Login,
//   LoginManager: manager,
//   LoginManagerStore: store,
//   LoginManagerActions: actions,
//   PrivateRoute: PrivateRoute,
//   withLogin: withLogin
// }

// export * from 'Login';
// export Fancy;