export const Actions = {
    INIT: 0,
    LOAD_COMPONENT: 1,
    UNLOAD_COMPONENT: 2
  };
  
export const States = {
    UNDEFINED: -1,
    INITIALIZED: 0,
    COMPONENT_LOADED: 1,
    COMPONENT_UNLOADED: 2
  };