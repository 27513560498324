import React, { Component } from 'react';
import { 
    Dropdown, DropdownMenu, DropdownItem, Progress, Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter, Button,
    Pagination, PaginationItem, PaginationLink,
    ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,
    Row, Col
} from 'reactstrap';
import BlockUi from 'react-block-ui';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { Loader, Types } from 'react-loaders';
import 'react-block-ui/style.css';
// import '../../../loaders.min.css';
import './Reset.css';
import {loginStore as store, LoginActions as actions} from './LoginManager'

const logdown = require('logdown')
const console = logdown('rx-login-reset', { prefixColor: '#689f38' })

export class Reset extends Component {


    constructor(props) {

        super(props);

        this.state = {
            newPassword: '',
            newPasswordConfirm: '',
            verificationCode: '',
            blocking: false,
            confirmed: false,
            username: this.props.username,
            blockingClass: "",
            blockingMessage: "CAMBIO LA PASSWORD",
            errorMessage: "",
            mailSent: false
        }

        // this.props.resetActions.subscribe(() => {
        //   this.setState({confirmed: true});
        // });
        this.onConfirm = this.props.onConfirm;

        this.handleVerificationCodeChange = this.handleVerificationCodeChange.bind(this);
        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.handleNewPasswordConfirmChange = this.handleNewPasswordConfirmChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.backToLogin = this.backToLogin.bind(this);
    
    }


    componentDidMount(){
        // console.log(`mount with username: ${this.state.username}`)
        this.setState({mailSent:false})
    }

  
    handleVerificationCodeChange(event) {
        this.setState({verificationCode: event.target.value});
    }
    
    
    handleNewPasswordChange(event) {
        this.setState({newPassword: event.target.value});
    }


    handleNewPasswordConfirmChange(event) {
        this.setState({newPasswordConfirm: event.target.value});
    }


    handleSubmit(e){

        console.log(`actions: ${JSON.stringify(actions)}`);

        console.log(`with user: ${this.props.username}`);
        this.setState({blocking: true});
        var cb = (success, failure)=>{

            if (failure){

                console.log(`failure: ${failure.code}`);
                this.setState({blocking: true, blockingMessage: `${failure.code}`, blockingColor: '#ff0000', blockingClass: "", errorMessage: `${failure.code}:${failure.message}`});
            
                setTimeout(() =>{
                    this.setState({blocking: false, blockingMessage: "CAMBIO LA PASSWORD"});
                }, 1000)

            }else{
                this.onConfirm(success);
            }

        }

        store.dispatch({type:actions.RESET, 
            verification_code: this.state.verificationCode,
            new_password: this.state.newPassword,
            username: this.props.username,
            cb: cb
        })
    }


    backToLogin(e){
        console.log('try to back to login');
        this.props.loginActions.dispatch({type: 'RESET_CONFIRM'});
    }


    render() {
        
        return (
            <Modal {...this.props}>
                <ModalHeader toggle={this.props.toggleModal} style={{width:'100%'}}>
                    <small>È stato richiesto un reset della password</small>
                </ModalHeader> 
                <ModalBody>
                    {!this.state.confirmed ?
                        <BlockUi tag="div" blocking={this.state.blocking} message={this.state.blockingMessage} loader={<Loader active type="line-scale-pulse-out" color="#4dbd74"/>}>
                            <div className="card-group mb-0">
                                <div className="card p-4">
                                    <div className="card-block">
                                        <AvForm>
                                            <AvGroup>
                                                <div className="input-group mb-3"> 
                                                    <span className="input-group-addon"><i className="icon-key"></i></span> 
                                                    <AvInput name="verificationCode" id="verificationCode" type="text" className="form-control" placeholder="Codice di Verifica" value={this.state.verificationCode} onChange={this.handleVerificationCodeChange} required/>
                                                </div> 
                                                <AvFeedback>Codice di Verifica Obbligatorio</AvFeedback>
                                            </AvGroup>
                                            <AvGroup>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-addon"><i className="icon-lock"></i></span>
                                                    <AvInput name="newPassword" id="newPassword" type="password" className="form-control" placeholder="Nuova Password" value={this.state.newPassword} onChange={this.handleNewPasswordChange} required/>
                                                </div>
                                                <AvFeedback>Password Obbligatoria</AvFeedback>
                                            </AvGroup>
                                            <AvGroup>
                                                <div className="input-group mb-4">
                                                    <span className="input-group-addon"><i className="icon-lock"></i></span>
                                                    <AvInput name="newPasswordConfirm" id="newPasswordConfirm" type="password" className="form-control" placeholder="Conferma Nuova Password" value={this.state.newPasswordConfirm} onChange={this.handleNewPasswordConfirmChange} validate={{match:{value:'newPassword'}}}/>
                                                </div>
                                                <AvFeedback>la Password non corrisponde</AvFeedback>
                                            </AvGroup>
                                            <div className="row">
                                                <div className="col-12">
                                                    <button type="button" className="btn btn-success btn-block px-4" onClick={(e) => this.handleSubmit(e)}>Salva la nuova password</button>
                                                    <Button style={{marginTop:"10px"}} onClick={(e) => this.props.onCancel()}>Annulla</Button>{' '}
                                                    <div style={{color:'red'}}>{this.state.errorMessage}</div>
                                                </div>
                                            </div>
                                        </AvForm>
                                    </div>
                                    </div>
                                <div className="card card-inverse card-success py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                                    <div className="card-block text-center">
                                        <div>
                                            <h2>Reset Password</h2>
                                            <p>Cliccare sul pulsante sottostante per ricevere via mail il codice di verifica all'indirizzo {this.props.username}. Quindi inserire la nuova password e il codice di verifica ricevuto.</p>
                                            <button disabled={this.state.mailSent}  onClick={() => 
                                                {this.props.onSendEmail();
                                                this.setState({mailSent:true})
                                                 
                                                }}> {this.state.mailSent ? "Email inviata" : "Ricevi una Email con il codice di verifica"}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </BlockUi>
                    :
                        <div className="card card-inverse py-5 d-md-down-none" style={{ width: 100 + '%' }}>
                            <div className="card-block text-center">
                                <div>
                                    <h2>Password Resettata</h2>
                                    <Button outline color="success" onClick={(e) => this.backToLogin(e)}>Ritorna alla login</Button>{' '}
                                </div>
                            </div>
                        </div>
                    }
                </ModalBody>
            </Modal>
        )
    }


}
