import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
// import navigation from '../../_nav';
// routes config
// import routes from '../../routes';
import RXASide from './RXASide';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import { createStore } from 'redux'

import {Actions, States} from './RXLayoutActions'

// const Actions = {
//   INIT: 0,
//   LOAD_COMPONENT: 1,
//   UNLOAD_COMPONENT: 2
// };

// const States = {
//   UNDEFINED: -1,
//   INITIALIZED: 0,
//   COMPONENT_LOADED: 1,
//   COMPONENT_UNLOADED: 2
// };

const logdown = require('logdown')
const console = logdown('rx-dashboard-default-layout', { prefixColor: '#00bcd4' })

const Reducer = (state = 0, action) => {
   switch (action.type) {
       
       case Actions.INIT:
           return {state: States.INITIALIZED};
   
       case Actions.LOAD_COMPONENT:
               console.log(`in update_location actions`);

           return {state: States.COMPONENT_LOADED, component: action.component};

        case Actions.UNLOAD_COMPONENT:
          return {state: States.COMPONENT_UNLOADED}
          
       default:
           return {state: States.UNDEFINED};
           
   }
}

class DefaultLayout extends Component {

  constructor(props){
    super(props);
    this.actionStore = createStore(Reducer);
    console.log(`NEW defaultLayout log props: ${Object.keys(props)}`);
    console.log(`component: ${props.component}`)
    console.log(`dashboardConfiguration: ${props.dashboardConfiguration}`)
    this.state = {
      navigation: {items: this.props.dashboardConfiguration.navigation}
    }

    //RETRIEVE AND BUILD ROUTES FROM CONF
    // this.routes = routes(this.props.dashboardConfiguration)
    this.routes = this.props.routes
  }


  render() {

    const {component, ...props} = this.props

    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader logo={this.props.logo} logoMinimized={this.props.logoMinimized} loginManager={this.props.loginManager}/>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={this.state.navigation} {...props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={this.routes}/>
            <Container fluid>
              <Switch>
                {this.props.routes.map((route, idx) => {
                    return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                        <route.component {...props} actionStore={this.actionStore} actions={Actions} managers={this.props.managers} />
                      )} />)
                      : (null);
                  },
                )}
                <Redirect from="/" to={this.props.defaultRoute} />
              </Switch>
            </Container>
            
            {/* <div class="fixed-bottom" style={{height:300, backgroundColor: 'green', zIndex: 1}}>
                  Ciao
            </div> */}
            {/* <div style={{backgroundColor: 'red', bottom: 0, height: 400, left:0, right:0, position:'absolute'}}>
                <div style={{backgroundColor:'white', position: 'absolute', top:0, left:0, right:0, height:32}}>
                    <div className="float-right"><i className="icon-phone">Test</i></div>
                </div>
            </div>    */}
          </main>
          <AppAside fixed>
            <RXASide actionStore={this.actionStore} states={States}/>
          </AppAside>
        </div>
        {/* <AppFooter>
          <DefaultFooter configuration={this.props.layoutConfiguration.footer}/>
        </AppFooter> */}
      </div>
    );
  }
}

DefaultLayout.defaultProps = {
  logo: null,
  logoMinimized: null
}

export default DefaultLayout;
