import React, { Component } from 'react';
import { 
    Dropdown, DropdownMenu, DropdownItem, Progress, Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter, Button,
    Pagination, PaginationItem, PaginationLink,
    ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,
    Row, Col
} from 'reactstrap';
import BlockUi from 'react-block-ui';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { Loader, Types } from 'react-loaders';
import {LoginActions as actions} from './LoginManager'
import 'react-block-ui/style.css';
import './New.css';

const logdown = require('logdown')
const console = logdown('rx-login-new', { prefixColor: '#689f38' })

export class New extends Component {


    constructor(props) {

        super(props);
        console.log(`retrieve props: ${JSON.stringify(props)}`);
        console.log(`loginstore: ${props.loginstore}`);
        this.state = {
            newPassword: '',
            newPasswordConfirm: '',
            verificationCode: '',
            blocking: false,
            blockingClass: "",
            blockingMessage: "CAMBIO LA PASSWORD",
            confirmed: false,
            errorMessage: ""
        }

        this.onConfirm = this.props.onConfirm;
        console.log(`after set onConfirm with ${this.onConfirm}`);
    
        // this.props.resetActions.subscribe(() => {
        //   this.setState({confirmed: true});
        // });

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.handleNewPasswordConfirmChange = this.handleNewPasswordConfirmChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.backToLogin = this.backToLogin.bind(this);
    
    }


    handleNameChange(event) {
        this.setState({name: event.target.value});
    }
    
    
    handleNewPasswordChange(event) {
        this.setState({newPassword: event.target.value});
    }
    
    
    handleNewPasswordConfirmChange(event) {
        this.setState({newPasswordConfirm: event.target.value});
    }


    handleSubmit(e){
        
        this.setState({blocking: true});
        var _this = this;
        var cb = function(success, failure){

            if (failure){

                console.log(`failure: ${failure.code}`);
                _this.setState({blocking: true, blockingMessage: `${failure.code}`, blockingColor: '#ff0000', blockingClass: "", errorMessage: `${failure.code}:${failure.message}`});
            
                setTimeout(function () {
                    _this.setState({blocking: false, blockingMessage: "CAMBIO LA PASSWORD"});
                    console.log('boo')
                }, 1000)

            }else{
                _this.onConfirm(success);
            }

        }

        this.props.loginstore.dispatch({type: actions.REQUEST_NEW_PASSWORD, name: this.state.name, password: this.state.newPassword, cb: cb});
    
    }


    backToLogin(e){
        console.log('try to back to login');
        this.props.loginActions.dispatch({type: 'RESET_CONFIRM'});
    }


    render() {

        return (
            <Modal {...this.props}>
                <ModalHeader toggle={this.props.toggleModal} style={{width:'100%'}}>
                    <small>È necessario cambiare la password</small>
                </ModalHeader> 
                <ModalBody>
                    {!this.state.confirmed ?
                        <BlockUi  tag="div" blocking={this.state.blocking} className={this.state.blockingClass} message={this.state.blockingMessage} loader={<Loader active type="line-scale-pulse-out" color={this.state.blockingColor}/>}>
                            <div className="card-group mb-0">
                                <div className="card p-4">
                                    <div className="card-block">
                                        <AvForm>
                                            <AvGroup>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-addon"><i className="icon-lock"></i></span>
                                                    <AvInput name="name" id="name" type="text" className="form-control" placeholder="Nome e Cognome" value={this.state.name} onChange={this.handleNameChange} required/>
                                                </div>
                                                <AvFeedback>Password Obbligatoria</AvFeedback>
                                            </AvGroup>
                                            <AvGroup>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-addon"><i className="icon-lock"></i></span>
                                                    <AvInput name="newPassword" id="newPassword" type="password" className="form-control" placeholder="Nuova Password" value={this.state.newPassword} onChange={this.handleNewPasswordChange} required/>
                                                </div>
                                                <AvFeedback>Password Obbligatoria</AvFeedback>
                                            </AvGroup>
                                            <AvGroup>
                                                <div className="input-group mb-4">
                                                    <span className="input-group-addon"><i className="icon-lock"></i></span>
                                                    <AvInput name="newPasswordConfirm" id="newPasswordConfirm" type="password" className="form-control" placeholder="Conferma Nuova Password" value={this.state.newPasswordConfirm} onChange={this.handleNewPasswordConfirmChange} validate={{match:{value:'newPassword'}}}/>
                                                </div>
                                                <AvFeedback>la Password non corrisponde</AvFeedback>
                                            </AvGroup>
                                            <div className="row">
                                                <div className="col-12">
                                                    <button type="button" className="btn btn-success btn-block px-4" onClick={(e) => this.handleSubmit(e)}>Salva la nuova password</button>
                                                    <div style={{color:'red'}}>{this.state.errorMessage}</div>
                                                </div>
                                            </div>
                                        </AvForm>
                                    </div>
                                </div>
                                <div className="card card-inverse card-success py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                                    <div className="card-block text-center">
                                        <div>
                                            <h2>New Password</h2>
                                            <p>Inserire la nuova password e il codice di verifica inviato via email</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </BlockUi>
                    :
                        <div className="card card-inverse py-5 d-md-down-none" style={{ width: 100 + '%' }}>
                            <div className="card-block text-center">
                                <div>
                                    <h2>Password Resettata</h2>
                                    <Button outline color="success" onClick={(e) => this.backToLogin(e)}>Ritorna alla login</Button>{' '}
                                </div>
                            </div>
                        </div>
                    }
                </ModalBody>
            </Modal>
        )
    }
}


// export default New;