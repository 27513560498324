import React, { Component } from 'react';
import { Container, Row, Col, CardGroup, Card, CardBody, Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

import {
    loginStore as store,
    LoginActions as actions,
    LoginManager
} from './LoginManager'

import BlockUi from 'react-block-ui';
import { Loader, Types } from 'react-loaders';
import 'react-block-ui/style.css';
import './loaders.min.css';

import { Reset } from './Reset';
import { New } from './New';

import './Login.css';

const logdown = require('logdown')
const console = logdown('rx-login-login', { prefixColor: '#689f38' })

export class Login extends Component {
    constructor(props) {

        super(props);

        console.log(`in Login with props: ${JSON.stringify(props)}`);

        this.loginTitle = "RX Login Component";

        if (this.props.loginTitle != null) {
            this.loginTitle = this.props.loginTitle;
        }

        this.state = {
            username: "",
            password: "",
            blocking: false,
            blockingMessage: '',
            blockingColor: '#000000',
            blockingClass: 'normal',
            resetModalOpen: false,
            newModalOpen: false,
            loginTitle: this.loginTitle
        };

        this.handleLogin = this.handleLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.loginManager = props.loginManager || new LoginManager();

        this.store = store;
        console.log(`in Login.js with this.loginManager: ${JSON.stringify(this.loginManager)}`);

        let unsubscribe = this.store.subscribe(() => {

            let state = store.getState();
            console.log(`APP loginStore state: ${JSON.stringify(state)}`);

            switch (state.state) {
                case actions.LOGIN_DONE:
                    console.log('login done - change to authenticated');
                    console.log(`before history push: ${this.props.history}`);
                    //this.props.history.push('/');
                    break;

                default:
                    break;
            }

        });
        //this.store.dispatch({type: actions.INIT});

    }


    componentDidMount() {
        this.usernameInput.focus();
    }


    handleChange(event, field) {

        console.log(`field: ${field}`);
        this.setState({ [event.target.id]: event.target.value });
    }


    handleLogin(event) {

        event.preventDefault();
        this.setState({ blocking: true, blockingClass: 'normal', blockingMessage: 'Accedo' });
        var _this = this;

        let cb = function (success, failure) {

            console.log(`called cb with success: ${success} - and failure ${failure}`);

            if (failure) {

                console.log(`in failure: ${JSON.stringify(failure)}`);

                switch (failure.error) {

                    case "UserNotFoundException":
                    case "NotAuthorizedException":

                        _this.setState({ blocking: true, blockingMessage: 'UTENTE O PASSWORD NON VALIDI', blockingColor: '#000000', blockingClass: 'alertMessage' });
                        setTimeout(function () {

                            _this.setState({ blocking: false });
                            console.log('boo')
                        }, 1000)
                        break;

                    case "PasswordResetRequiredException":

                        console.log(`in passwordResetRequiredExeption`);
                        _this.setState({ resetModalOpen: true });
                        break;

                    case "NewPasswordRequired":

                        console.log(`try to open newModal with NewPasswordRequired`);

                        _this.setState({ newModalOpen: true });
                        break;

                    case "UserNotConfirmedException":

                        _this.setState({ blocking: true, blockingMessage: 'UTENTE NON AUTORIZZATO - CONTATTARE ADMIN', blockingColor: '#000000', blockingClass: 'alertMessage' });

                        setTimeout(function () {
                            _this.setState({ blocking: false });
                            console.log('boo')
                        }, 3000)
                        break;

                    default:

                        _this.setState({ blocking: true, blockingMessage: 'ERRORE GENERICO', blockingColor: '#000000', blockingClass: 'normal' });

                        setTimeout(function () {

                            _this.setState({ blocking: false });
                            console.log('boo')
                        }, 1000)
                        break;
                }

            }
        }

        console.log('in handleLogin');
        this.store.dispatch({ type: actions.LOGIN, username: this.state.username, password: this.state.password, cb: cb });
    }


    onKeyPress = (input, event) => {

        if (event.key === 'Enter') {
            console.log(`in okKeypress with input ${input}`)
            if (input === 'username') {
                console.log('move to password');
                this.passwordInput.focus();
            } else if (input === 'password') {
                console.log('submit');
                this.handleLogin(event);
            }
        }
    }


    render() {

        return (

            <div className="app flex-row align-items-center">

                {this.state.resetModalOpen &&
                    <Reset
                        isOpen={this.state.resetModalOpen}
                        className={'modal-success modal-lg ' + this.props.className}
                        username={this.state.username}
                        onCancel={() => { this.setState({ resetModalOpen: false }) }}
                        onSendEmail={() => { this.loginManager.forgotPasswordRequest(this.state.username) }}
                        onConfirm={(data) => {
                            console.log(`on confirm with ${JSON.stringify(data)}`);
                            this.setState({ resetModalOpen: false, blocking: false, username: this.state.username, password: "" });
                            this.passwordInput.focus();
                        }}
                    />
                }
                <New
                    isOpen={this.state.newModalOpen}
                    className={'modal-success modal-lg ' + this.props.className}
                    loginstore={this.store}
                    onConfirm={(data) => {
                        console.log(`on confirm with ${JSON.stringify(data)}`);
                        this.setState({ newModalOpen: false, blocking: false, username: "", password: "" });
                    }}
                />
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <BlockUi tag="div" className={this.state.blockingClass} blocking={this.state.blocking} message={this.state.blockingMessage}
                                loader={<Loader active type="line-scale-pulse-out" color={this.state.blockingColor} />}
                            >
                                <CardGroup className="mb-0">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col xs="12" sm="12" lg="12">
                                                    <div className="col-12 col-sm-12 col-lg-12"><img height={"150px"}
                                                        src={"assets/img/outsiders_Logoscritta.png"} className="rounded mx-auto d-block" /></div>

                                                </Col>
                                            </Row>
                                            <br></br>
                                            <h3 className="pageDescription" style={{ textAlign: 'center', color: 'grey' }}>{this.state.loginTitle}</h3>
                                            {/** <p className="text-muted-">Accedi con le tue credenziali</p> **/}
                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="icon-user"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    innerRef={(input) => { this.usernameInput = input; }}
                                                    placeholder="Username"
                                                    id="username"
                                                    value={this.state.username}
                                                    onChange={this.handleChange}
                                                    onKeyPress={(e) => this.onKeyPress('username', e)}
                                                />
                                            </InputGroup>
                                            <InputGroup className="mb-4">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="icon-lock"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="password"
                                                    innerRef={(input) => { this.passwordInput = input; }}
                                                    placeholder="Password"
                                                    id="password"
                                                    value={this.state.password}
                                                    onChange={this.handleChange}
                                                    onKeyPress={(e) => this.onKeyPress('password', e)}
                                                />
                                            </InputGroup>
                                            <Row>
                                                <Col xs="6">
                                                    <Button color="primary" className="px-4" onClick={this.handleLogin}>Login</Button>
                                                </Col>
                                                <Col xs="6" className="text-right">
                                                    <Button color="orange"
                                                        onClick={(ev) => { this.setState({ resetModalOpen: true }); }}
                                                        className="px-4 btn btn-primary">Password dimenticata?</Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </CardGroup>
                            </BlockUi>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
