import React from 'react';
import Loadable from 'react-loadable'
var path = require('path')

function Loading() {
  return <div>RX Loading...</div>;
}

const logdown = require('logdown')
const console = logdown('rx-dashboard-routes', { prefixColor: '#00bcd4' })

function getLoadableComponent(route, configurations, loaders, global, loadingFunc){

    var module = undefined;
    var subComponentName = undefined;
    var routes = route.page.split(".")
    var importFunction = undefined
    if(route.loader){
      console.log(`loaders ${Object.keys(loaders)}`)
      console.log(`use custom loader ${route.loader}`)
      importFunction = ()=>loaders[route.loader](route.page)
      if(routes.length>1){
        subComponentName = routes[1];      
      }
    }else{
      console.log('in normal path')
      if(routes.length>1){
        module = `./config/modules/${routes[0]}`;
  
        subComponentName = routes[1];
        console.log(`found subComponent with name ${subComponentName} for module ${module}`)
        importFunction = ()=>import(`${module}`)
      }else{
        module = `./pages/${route.page}`;
        console.log(`load 2 simple page from ${module}`)
        importFunction = ()=>import(`${module}`)
      }
      
    }

    return Loadable({
        loader: () => importFunction()
        .then((success)=>{
          console.log(`in success load`)
          return success
        })
        .catch((error)=>{
          console.log(`Error loading component: ${error}`)
        }),
        render(Component, props){

          //Inject local module configuration with global configurations
          var parameters = route.parameters? route.parameters : {}
          parameters = {global, ...parameters};
          
          if(route.configuration){
            var configuration = configurations[route.configuration] ? configurations[route.configuration] : {} 
            parameters = {...configuration, ...parameters}
          }

          if(subComponentName){
              var SubComponent = undefined
              if (Component.default){
                SubComponent = Component.default[subComponentName];
              }else{
                SubComponent = Component[subComponentName];
              }
              if(route.aside){
                // console.log(`component: ${Object.keys(Component)}`)
                const ASideComponent = Component[route.aside];
                // console.log(`aside: ${ASideComponent}`)
                return <SubComponent aside={ASideComponent} {...{...parameters, ...props}} />;

              }
              return <SubComponent {...{...parameters, ...props}} />;
            }
            return <Component.default {...{...parameters, ...props}} />;
        },
        loading: loadingFunc,
        delay: 400,

      });
}

export const getRoutes = (configuration, loaders, loading) => {

  const baseRoutes = [
  ]
  
  return  [
      ...baseRoutes,
      ...configuration.routes.map((route, index)=>{
          console.log(`iterate for ${JSON.stringify(route)}`)
          return { path: route.path, name: route.name, component: getLoadableComponent(route, configuration.configurations, loaders, configuration, loading) }
      })
  ];
}

export const getPublicRoutes = (configuration, loaders, loading) => {

  const baseRoutes = [
  ]
  
  return  [
      ...baseRoutes,
      ...configuration.publicRoutes.map((route, index)=>{
          console.log(`public iterate for ${JSON.stringify(route)}`)
          return { path: route.path, name: route.name, component: getLoadableComponent(route, configuration.configurations, loaders, configuration, loading) }
      })
  ];
}
 
// export default getRoutes;
